/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'translate': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M8.418 9.614a.639.639 0 00-.15-.677L6.92 7.604l.02-.019a11.325 11.325 0 002.398-4.221h1.248a.646.646 0 000-1.293h-3.88v-.647a.646.646 0 00-1.293 0v.647H1.533a.643.643 0 000 1.286H8.11a10.158 10.158 0 01-2.05 3.465A10.101 10.101 0 014.74 4.98a.622.622 0 00-.54-.323c-.435 0-.723.447-.512.828.414.748.92 1.46 1.513 2.12l-2.827 2.787a.647.647 0 10.912.918L6.06 8.535l1.308 1.308a.639.639 0 001.05-.229zm4.01-3.06a.933.933 0 00-1.747 0L8.307 12.89a.605.605 0 101.133.424l.578-1.546h3.07l.583 1.547a.606.606 0 001.134-.426l-2.375-6.334zm-1.92 3.92l1.047-2.798 1.048 2.799h-2.095z" _fill="#fff"/>'
  }
})
